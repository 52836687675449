import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import PageWrap from '../components/pageWrap'
import P from '../components/paragraph'

const ProductsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ProductsPageImage: file(relativePath: { eq: "laptop.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.ProductsPageImage.childImageSharp}>
          Our Technology
        </PageHeader>
        <PageWrap>
          <h2>About SCIT</h2>
          <P>
            Reliable organizations have a constant presence on the internet. The
            static nature of the online server makes it an easy target to
            exploit. At SCIT Labs, we believe that your reliability should not
            compromise your security or reputation.
          </P>
          <P>
            SCIT reduces Cyber Risk. Existing Cyber Security products focus on
            eliminating vulnerabilities. SCIT focuses on Consequence Management.
            SCIT integrates seamlessly with existing security approaches to make
            hackers lives’ more difficult.
          </P>
          <P>
            SCIT simplifies Operations. Security and functionality upgrades lead
            to frequent patches. Servers equipped with SCIT accept hot patches,
            and no server reboots are required for application patches. SCIT
            also eliminates memory leaks.
          </P>
          <P>
            SCIT reduces Operations Costs. By forcing the criminals into making
            repeated intrusion attacks, SCIT increases the exposure of malicious
            activity and reduces false positives. Hot patches, no memory leaks,
            and reduced false positives lower operational costs.
          </P>
          <P>
            SCIT supports Continuous Monitoring. SCIT is interfaced to forensics
            and analysis tools. SCIT’s cycle enables monitoring of the status of
            the online virtual machines. Post-exploit and post-failure analysis
            provides early warning of deviation from expected behavior.
          </P>
          <P>
            SCIT supports Incidence Analysis. SCIT in combination with forensic
            tools helps in post-exploit analysis and identification of the
            attacker’s methodology and exploited vulnerabilities. These results
            can be incorporated in the Incidence Response life cycle and used to
            enhance the prevention capability.
          </P>
        </PageWrap>
        <PageWrap>
          <h2>How SCIT Works</h2>
          <P>
            Self- Cleaning Intrusion Tolerance is a patented technique for
            providing ultra-low intruder persistence time. We constantly restore
            systems to a pristine state to remove malware and rob intruders of
            the time needed to plan and launch attacks. SCIT does not require
            changes to existing information systems, applications, or security
            protocols to deliver new high level of protection. When intruders
            get in, you need to throw them out as quickly as possible without
            waiting to figure out what they are up to. SCIT-MTD (SCIT Moving
            Target Defense) assumes that while intrusions are inevitable, the
            bigger problem is that intruders are in your systems for a very long
            time watching how your system works. Once they are in, they learn
            how your systems operate, where your most valuable assets are
            located, and how to get your data out of your system under your
            security radar. SCIT disrupts the hacker processes and makes it
            difficult for hackers to succeed.
          </P>
          <h2>How does SCIT Moving Target Defense Work?</h2>
          <P>
            SCIT Moving Target Defense continuously restores and recovers making
            it difficult for the attack to persist. Even if they get in, they do
            not have the time to continue and complete the attack. Using
            virtualization technology, SCIT-MTD rotates pristine virtual servers
            and applications at configurable intervals as low as every minute.
            Eventually every server will be taken offline, cleaned and restored
            to its pristine state. The speed of rotation means, that the
            adversary won’t be able to persist in the system and exploit the
            vulnerability. If an attacker is able to place malware on a server,
            the malware will be deleted without reliance on a detection
            processes. SCIT-MTD allows systems to continue working through an
            attack with automatic and rapid recovery to a clean state. SCIT-MTD
            enabled systems offer new protection from zero day vulnerability
            intrusions. SCIT- MTD’s ultra-low persistence time removes malware
            and cuts down the time intruders have to exploit the risk. SCIT-MTD
            enabled systems are substantially more secure even if there are
            vulnerabilities present because the timeframe that the attacker has
            to exploit the vulnerability is equal to the time it takes to a
            trigger a new server rotation.
          </P>
        </PageWrap>
      </Layout>
    )}
  />
)

export default ProductsPage
